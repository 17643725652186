// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'waMast', // this used as the field name as well as the unique identifier
    productIds: [
      '47e98fd4-fa3b-4689-5402-08dc4ff57628', // Washington Mandatory Alcohol Server Training, Class 13
    ],
    errorMsg: `You must attest that you understand the <strong>Washington Mandatory Alcohol Server Training Class 13</strong> terms by checking the highlighted checkbox.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'checkbox', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb() {
  return (
    <>
      By checking this box, you are attesting that you are currently{' '}
      <strong className="text-decoration-underline">18, 19, or 20 years of age</strong> and eligible to obtain your{' '}
      <strong>Washington MAST Class 13</strong> permit OR purchasing a voucher for those that are{' '}
      <strong className="text-decoration-underline">18, 19, or 20 years of age</strong>. You also understand there will be no
      refunds for purchasing and completing the wrong course or for distributing voucher links to those who do not meet the
      age parameters.
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return null;
}
