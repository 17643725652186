// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'caTrafficViolator', // this used as the field name as well as the unique identifier
    productIds: [
      'e3ece6c4-5389-4504-9f88-08dc3e2255e2', // California Traffic School, Elective
      'be1c31e1-0858-46ec-9f89-08dc3e2255e2', // California Traffic School Streaming, Elective
    ],
    errorMsg: `You must attest that you read and understand the <strong>California Traffic Violator</strong> course customer disclosure statement by typing your <strong>full legal name</strong> in the highlighted box.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'signature', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb({ handleModal }) {
  return (
    <>
      To purchase the <strong>California Traffic Violator</strong> course, please type your <strong>full legal name</strong>{' '}
      in the box below to attest that you have read and understand the{' '}
      <a href="#" onClick={handleModal}>
        customer disclosure statement
      </a>
      .
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return (
    <>
      <h3 className="modal-title fw-600 mb-3">California Traffic Violator Disclosure Statement</h3>
      <p>
        Course content is limited to traffic violator curricula approved by the Department of Motor Vehicles. Students in the
        classroom include traffic offenders, repeat traffic offenders, adults, and teenagers, and those who have and those
        who have not been referred by a court. Instructor training, business regulatory standards, and Vehicle Code
        requirements of traffic violator schools are not equal to the training, standards, and Vehicle Code requirements of
        licensed driving schools (
        <a
          href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?lawCode=VEH&division=5.&title=&part=&chapter=1.5.&article="
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          California Vehicle Code Section 11200(b)(1)
        </a>
        ).
      </p>
    </>
  );
}
