import { useState, useEffect, useContext, useRef } from 'react';
import { formatCurrency as currency } from '../utils/formatCurrency';
import { getDisplayPrice, getOrigPrice } from '../utils/amountHelper';
import { ecomAddProduct, ecomRemoveProduct } from '../utils/ecomHelper';
import API from '../services/apiService';
import { CartContext } from '../store/CartContext';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
UpsellDigital.propTypes = {
  item: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

export default function UpsellDigital({ item, prefix = '' }) {
  const [cartState, cartDispatch] = useContext(CartContext);

  const digitalUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 3)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

  const digitalUpsellsProductIds = digitalUpsells.map((item) => item.productId);

  const currentUpsell = digitalUpsells.filter((upsell) => upsell.isSelected)[0];
  // console.log(currentUpsell?.cart_ProductId);

  const [selectedUpsell, setSelectedUpsell] = useState({});
  const [currentUpsellId, setCurrentUpsellId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setSelectedUpsell((state) => ({
      ...state,
      productId: currentUpsell?.productId || '00000000-0000-0000-0000-000000000000',
      prevProductId: null,
      cartProductId: currentUpsell?.cart_ProductId || '00000000-0000-0000-0000-000000000000',
      prevCartProductId: null,
    }));
  }, [currentUpsell]);

  const storeCart = (cartData) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        ...cartData,
        isLoading: false,
      },
    });
  };

  const storeCartProducts = (updatedCartProducts) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        cartProducts: updatedCartProducts,
        isLoading: true,
      },
    });
  };

  const upsellDebounce = useRef();

  const handleChange = (event) => {
    const { value, type, checked, dataset } = event.target;
    const theValue = type === 'checkbox' ? checked : value;

    setIsUpdating(true);
    setCurrentUpsellId(value);

    const selectedProductId = theValue;
    const selectedCartProductId = dataset.cartProductId;
    const prevProductId = selectedUpsell.productId;
    const prevCartProductId = selectedUpsell.cartProductId;

    // console.log(prevProductId);
    // console.log(selectedProductId);

    setSelectedUpsell(() => ({
      productId: selectedProductId,
      prevProductId: prevProductId,
      cartProductId: selectedCartProductId,
      prevCartProductId: prevCartProductId,
    }));

    // if isSelected remove the other selected shipping upsell
    const updatedCartProducts = cartState?.cartProducts?.map((product) => {
      if (product.productId === item.productId) {
        const upsells = product.upSellProducts;
        const updatedUpsells = upsells.map((productUpsell) => {
          // removes previusly selected digital upsell
          if (digitalUpsellsProductIds.includes(productUpsell.productId)) {
            return {
              ...productUpsell,
              isSelected: productUpsell.productId === selectedProductId,
            };
          }
          return productUpsell;
        });

        return {
          ...product,
          upSellProducts: updatedUpsells,
        };
      }
      return product;
    });

    storeCartProducts(updatedCartProducts);

    // debouncing API call in case the user repeated clicks the upsell option
    clearTimeout(upsellDebounce.current);
    upsellDebounce.current = setTimeout(() => {
      const upsellData = {
        cartProductId: selectedCartProductId, // upsell cart_ProductId
        cartId: item.cartId, // cartId
        productId: selectedProductId,
        quantity: item.quantity, // use quantity of main product or you can leave 1, it will automatically use the main product's quantity
        isSelected: true, // true/false, add or remove upsell
      };

      const prevUpsellData = {
        cartProductId: prevCartProductId, // upsell cart_ProductId
        cartId: item.cartId, // cartId
        productId: prevProductId,
        quantity: item.quantity, // use quantity of main product or you can leave 1, it will automatically use the main product's quantity
        isSelected: false, // true/false, add or remove upsell
      };

      const upsellForAdd = digitalUpsells.filter((upsell) => upsell.productId === upsellData.productId)[0];
      const upsellForRemoval = digitalUpsells.filter((upsell) => upsell.productId === prevUpsellData.productId)[0];

      if (selectedProductId === '00000000-0000-0000-0000-000000000000') {
        API.updateUpsell(prevUpsellData).then((res) => {
          // console.log('cart', res.data);
          const cart = res.data;

          setIsUpdating(false);

          storeCart(cart);

          // push event and data to dataLayer
          ecomRemoveProduct(upsellForRemoval);
        });
      } else {
        API.updateUpsell(upsellData)
          .then((res) => {
            // console.log('cart', res.data);
            const cart = res.data;

            // push event and data to dataLayer
            ecomAddProduct(upsellForAdd);

            return cart;
          })
          .then((cartData) => {
            if (prevProductId !== '00000000-0000-0000-0000-000000000000') {
              API.updateUpsell(prevUpsellData).then((res) => {
                // console.log('cart', res.data);
                const cart = res.data;
                setIsUpdating(false);
                storeCart(cart);

                // push event and data to dataLayer
                ecomRemoveProduct(upsellForRemoval);
              });
            } else {
              setIsUpdating(false);
              storeCart(cartData);
            }
          })
          .catch((err) => {
            console.error(err?.response?.data);

            // show error message or reload the page
            cartDispatch({
              type: 'ERROR',
              payload: {
                isLoading: false,
                isError: true,
                error: err?.response?.data || 'General Error',
              },
            });

            if (err?.response?.data?.status >= 400) {
              console.error('Upsell was not updated!');
            }
          });
      }
    }, 300);
  };

  const digitalUpsellRadios = digitalUpsells.map((upsell) => {
    const dispayPrice = getDisplayPrice(upsell, true);
    const origPrice = getOrigPrice(upsell, true);

    return (
      <div className="form-check" key={upsell.cart_ProductId}>
        {isUpdating && upsell.productId === currentUpsellId ? (
          <span className="spinner-border spinner-border-sm upsell-check" role="status" aria-hidden="true"></span>
        ) : (
          <input
            className="form-check-input"
            type="radio"
            name="digital"
            value={upsell.productId}
            id={`${prefix}upsell-cart-id-${upsell.cart_ProductId}`}
            checked={upsell.productId === selectedUpsell.productId}
            onChange={handleChange}
            data-cart-product-id={upsell.cart_ProductId}
            data-upsell-type={upsell.upsellType}
          />
        )}
        <label className="form-check-label" htmlFor={`${prefix}upsell-cart-id-${upsell.cart_ProductId}`}>
          <span className="fw-600 d-block mb-1">
            <span className="upsell-name">{upsell.name}</span>{' '}
            <span className="upsell-price d-inline-block">
              (
              {upsell.discountSource && upsell.discountSource !== 'BuiltIn' && (
                <span className="orig-price me-2">+{currency(origPrice)}</span>
              )}
              +{currency(dispayPrice)})
            </span>
          </span>
          <span className="upsell-description d-block lh-15">{upsell.description}</span>
        </label>
      </div>
    );
  });

  return (
    <form>
      <div className="form-check">
        {isUpdating && '00000000-0000-0000-0000-000000000000' === currentUpsellId ? (
          <span className="spinner-border spinner-border-sm upsell-check" role="status" aria-hidden="true"></span>
        ) : (
          <input
            className="form-check-input"
            type="radio"
            name="digital"
            value="00000000-0000-0000-0000-000000000000"
            id={`${prefix}upsell-cart-id-${item.cart_ProductId}-standard`}
            checked={'00000000-0000-0000-0000-000000000000' === selectedUpsell.productId}
            onChange={handleChange}
            data-cart-product-id="00000000-0000-0000-0000-000000000000"
          />
        )}
        <label className="form-check-label" htmlFor={`${prefix}upsell-cart-id-${item.cart_ProductId}-standard`}>
          <span className="fw-600 d-block mb-1">Standard Digital Delivery (Free)</span>
          <span className="d-block lh-15">
            Certificate processed and available for download within 5 days of course completion.
          </span>
        </label>
      </div>

      {digitalUpsellRadios}
    </form>
  );
}
